<template>
    <div class="app-container">
        <div class="filter-container">
            <el-button class="filter-item" type="primary" size="small" @click="$router.go(-1)">返回</el-button>

            <div style="margin:20px 0;color: red;">*注意：收单商户需开通【交易分账】、【微信小程序支付】、【提现到储蓄卡】业务，不收单商户需开通【提现到储蓄卡】业务</div>

            <el-button class="filter-item" type="primary" size="small" @click="handleCreate">添加</el-button>

<!--            <el-select v-model="listQuery.state" placeholder="状态" size="small" class="filter-item" style="width: 120px" clearable>-->
<!--                <el-option label="未启用" :value="0" />-->
<!--                <el-option label="已启用" :value="1" />-->
<!--            </el-select>-->

<!--            <el-button class="filter-item" type="primary" size="small" @click="handleFilter">搜索</el-button>-->
        </div>

        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
            <el-table-column label="ID" align="center" width="80">
                <template slot-scope="scope">
                    {{ scope.row.id }}
                </template>
            </el-table-column>

            <el-table-column label="商户名称" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.merchant ? scope.row.merchant.merchant_name : '' }}
                </template>
            </el-table-column>

            <el-table-column label="是否收单" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.merchant && scope.row.merchant.accept_order === 1" type="success">是</el-tag>
                    <el-tag v-else-if="scope.row.merchant && scope.row.merchant.accept_order === 0" type="danger">否</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="业务" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.business_code === 'TRADE_SPLIT'" type="warning">交易分账</el-tag>
                    <el-tag v-else-if="scope.row.business_code === 'WX_MINI_PROGRAM'" type="success">微信小程序支付</el-tag>
                    <el-tag v-else-if="scope.row.business_code === 'WITHDRAW_TO_SETTMENT_DEBIT'" type="danger">提现到储蓄卡</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="结算周期" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.settle_cycle === 'D+0'" type="primary">D+0</el-tag>
                    <el-tag v-else-if="scope.row.settle_cycle === 'D+1'" type="warning">D+1</el-tag>
                </template>
            </el-table-column>

<!--            <el-table-column label="状态" min-width="110" align="center">-->
<!--                <template slot-scope="scope">-->
<!--                    <el-tag v-if="scope.row.state === 1" type="success">已启用</el-tag>-->
<!--                    <el-tag v-else-if="scope.row.state === 0" type="danger">未启用</el-tag>-->
<!--                </template>-->
<!--            </el-table-column>-->

<!--            <el-table-column label="备注" min-width="110" align="center">-->
<!--                <template slot-scope="scope">-->
<!--                    {{ scope.row.msg }}-->
<!--                </template>-->
<!--            </el-table-column>-->

<!--            <el-table-column label="操作" fixed="right" align="center" width="200" class-name="small-padding fixed-width">-->
<!--                <template slot-scope="scope">-->
<!--                    <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">编辑</el-button>-->
<!--                </template>-->
<!--            </el-table-column>-->
        </el-table>

        <div class="pagination-container">
            <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>

        <!-- 详情 -->
        <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="50%" :close-on-click-modal="false">
            <el-form ref="form" :rules="rules" :model="form" label-position="right" label-width="150px">
                <div style="font-size:12px;color:red;margin-bottom:20px;">*注意：【交易分账】、【提现到储蓄卡】周期为【D+0】，【微信小程序支付】周期为【D+1】</div>

                <el-form-item label="业务" prop="business_code">
                    <el-radio-group v-model="form.business_code" :disabled="!!form.id">
                        <el-radio label="TRADE_SPLIT">交易分账</el-radio>
                        <el-radio label="WX_MINI_PROGRAM">微信小程序支付</el-radio>
                        <el-radio label="WITHDRAW_TO_SETTMENT_DEBIT">提现到储蓄卡</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="结算周期" prop="settle_cycle">
                    <el-radio-group v-model="form.settle_cycle" :disabled="!!form.id">
                        <el-radio label="D+0">D+0</el-radio>
                        <el-radio label="D+1">D+1</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
            </div>
        </el-dialog>
        <!-- 详情 -->
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";

    export default {
        data() {
            return {
                list: null,
                total: null,
                listLoading: true,
                listQuery: {
                    page: 1,
                    limit: 10,
                    school_id: '',
                    merchant_id: '',
                    state: '',
                },
                dialogFormVisible: false,
                dialogStatus: '',
                textMap: {
                    update: '编辑',
                    create: '添加',
                },
                btnLoading: false,
                form:{
                    id: '',
                    school_id: '',
                    merchant_id: '',
                    business_code: '',
                    settle_cycle: '',
                },
                rules: {
                    business_code: [{ required: true, message: '业务不能为空', trigger: 'change' }],
                    settle_cycle: [{ required: true, message: '结算周期不能为空', trigger: 'change' }],
                },
                merchant_id: '',
            };
        },
        created() {
            this.merchant_id = this.$route.query.merchant_id-0;
            this.getList();
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"]),
        },
        watch: {
            school_id(newVal, oldVal) {
                this.listQuery.school_id = newVal;
                this.getList();
            },
        },
        methods: {
            getList() {
                this.listQuery.school_id = this.school_id;
                this.listQuery.merchant_id = this.merchant_id;
                this.listLoading = true;
                request({
                    url: "/api/backend/merchantBusiness/list",
                    method: "get",
                    params: this.listQuery
                }).then(response => {
                    this.list = response.data.data;
                    this.total = response.data.total;
                    this.listLoading = false;
                });
            },
            handleFilter() {
                this.listQuery.page = 1;
                this.getList();
            },
            handleSizeChange(val) {
                this.listQuery.limit = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getList();
            },
            resetForm() {
                this.form = {
                    id: '',
                    school_id: '',
                    merchant_id: '',
                    business_code: '',
                    settle_cycle: '',
                }
                this.btnLoading = false
            },
            handleCreate() {
                this.resetForm()
                this.dialogStatus = 'create'
                this.dialogFormVisible = true
                this.$nextTick(() => {
                    this.$refs['form'].clearValidate() //清除校验结果
                })
            },
            handleUpdate(row) {
                this.resetForm()
                this.form = Object.assign({}, row)
                this.dialogStatus = 'update'
                this.dialogFormVisible = true
                this.$nextTick(() => {
                    this.$refs['form'].clearValidate() //清除校验结果
                })
            },
            saveData() {
                if (!this.school_id) {
                    this.$message({
                        type: "warning",
                        message: "请选择学校"
                    });
                    return;
                } else {
                    this.form.school_id = this.school_id;
                }

                if (!this.merchant_id) {
                    this.$message({
                        type: "warning",
                        message: "请选择商户"
                    });
                    return;
                } else {
                    this.form.merchant_id = this.merchant_id;
                }

                if ((this.form.business_code === 'TRADE_SPLIT' || this.form.business_code === 'WITHDRAW_TO_SETTMENT_DEBIT') && this.form.settle_cycle !== 'D+0') {
                    this.$message({
                        type: "warning",
                        message: "结算周期只能是【D+0】"
                    });
                    return;
                }

                if (this.form.business_code === 'WX_MINI_PROGRAM' && this.form.settle_cycle !== 'D+1') {
                    this.$message({
                        type: "warning",
                        message: "结算周期只能是【D+1】"
                    });
                    return;
                }

                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.btnLoading = true
                        request({
                            url: '/api/backend/merchantBusiness/save',
                            method: 'post',
                            data: this.form
                        }).then(() => {
                            this.btnLoading = false
                            this.dialogFormVisible = false
                            this.$message({
                                type: 'success',
                                message: '操作成功'
                            });
                            this.getList();
                        }).catch(() => {
                            this.btnLoading = false
                        })
                    }
                })
            },
        }
    };
</script>


<style rel="stylesheet/scss" lang="scss" scoped>

</style>
